import './bootstrap'

import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import { format, formatDistance, subDays } from 'date-fns'
import Choices from 'choices.js';

window.Alpine = Alpine;
Alpine.plugin(focus);
Alpine.start();

//import { createApp } from 'vue'
//import Welcome from './components/welcome'

//const app = createApp({})

//app.component('welcome', Welcome)

//app.mount('#app')
